<div class="w-full min-h-screen bgSignIn">
  <div class="relative">
    <back class="self-start absolute pt-6 pl-6"></back>
  </div>
  <!-- <div class="px-5 pt-5 -mb-12 absolute">
      <div class=" w-auto rounded-lg px-3 py-1 bg-white border-primary-700 border-2 transition-all duration-300">
        <back class="self-start mt-5"></back>
      </div>
    </div> -->
  <div class="flex items-center md:min-h-screen justify-center flex-col sm:px-5">
    <div></div>
    <div
      class="w-full dark:border md:mt-0 sm:max-w-lg md:max-w-4xl xl:p-0 dark:bg-gray-800 dark:border-gray-700"
    >
      <div class="p-6 space-y-2 md:space-y-6">
        <form
          [formGroup]="signUpForm"
          class="space-y-4 md:space-y-6 bgEsp mt-16 md:mt-0 bg-white shadow"
        >
        <div class="pt-1">
          <a>
            <img
              class="w-24 m-auto"
              src="../../../../../assets/img/logo.png"
              alt=""
            />
          </a>
        </div>
          <div class="text-center flex flex-col h1Mg mb-2 text-black">
            <b class="bMg font-black">Sign up</b
            >
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2">
            <div class="px-7 pb-3 col-span-1">
              <mat-form-field class="w-full" appearance="outline">
                <mat-icon matPrefix class="text-gray-700 text-md text-center"
                  >person</mat-icon
                >
                <mat-label>First name</mat-label>
                <input
                  class="focus:ring-0"
                  matInput
                  type="text"
                  formControlName="firtName"
                />
                <mat-error
                  *ngIf="signUpForm.get('firtName')?.touched
                && signUpForm.get('firtName')?.errors?.['required']"
                  class="text-xs"
                  >This field is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="px-7 pb-3 col-span-1">
              <mat-form-field class="w-full" appearance="outline">
                <mat-icon matPrefix class="text-gray-700 text-md text-center"
                  >person</mat-icon
                >
                <mat-label>Last name</mat-label>
                <input
                  class="focus:ring-0"
                  matInput
                  type="text"
                  formControlName="lastName"
                />
                <mat-error
                  *ngIf="signUpForm.get('lastName')?.touched
                                    && signUpForm.get('lastName')?.errors?.['required']
                                    "
                  class="text-xs"
                  >This field is required</mat-error
                >
              </mat-form-field>
            </div>
            <div class="px-7 pb-3 col-span-1">
              <mat-form-field class="w-full" appearance="outline">
                <mat-icon matPrefix class="text-gray-700 text-md text-center"
                  >email</mat-icon
                >
                <mat-label>Email</mat-label>
                <input
                  class="focus:ring-0"
                  matInput
                  type="email"
                  formControlName="email"
                />
                <mat-error
                  *ngIf="signUpForm.get('email')?.touched
                && signUpForm.get('email')?.errors?.['required']
                                    "
                  class="text-xs"
                  >This field is required</mat-error
                >
                <mat-error
                  *ngIf="signUpForm.get('email')?.touched
                                  && signUpForm.get('email')?.errors?.['email']
                                  "
                  class="text-xs"
                  >Invalid Email</mat-error
                >
              </mat-form-field>
            </div>
            <div class="px-7 pb-3 col-span-1">
              <mat-form-field class="w-full" appearance="outline">
                <span matTextPrefix>+1 &nbsp;</span>
                <mat-label>Phone</mat-label>
                <input
                  class="focus:ring-0"
                  matInput
                  mask="(000) 000-0000"
                  [showMaskTyped]="true"
                  type="tel"
                  formControlName="phone"
                />
                <mat-error
                  *ngIf="signUpForm.get('phone')?.touched
                                    && signUpForm.get('phone')?.errors?.['required']
                                    "
                  class="text-xs"
                  >This field is required</mat-error
                >
                <mat-error
                  *ngIf="signUpForm.get('phone')?.touched
              && signUpForm.get('phone')?.errors?.['minlength']"
                  class="text-xs"
                  >The phone number is 10 characters</mat-error
                >
                <mat-error
                  *ngIf="signUpForm.get('phone')?.touched
            && signUpForm.get('phone')?.errors?.['maxlength']"
                  class="text-xs"
                  >The phone number is 10 characters</mat-error
                >
                <mat-error
                  *ngIf="signUpForm.get('phone')?.touched
          && signUpForm.get('phone')?.errors?.['pattern']"
                  class="text-xs"
                  >Only numbers are permitted</mat-error
                >
              </mat-form-field>
            </div>
            <div class="px-7 pb-3 col-span-1 md:col-span-2">
              <div class="grid grid-cols-1 md:grid-cols-2">
                <div class="col-span-1 md:pe-7">
                  <mat-form-field class="w-full" appearance="outline">
                    <mat-label>Date of Birth</mat-label>
                    <input
                      matInput
                      [matDatepicker]="deliveryPicker"
                      formControlName="birthdate"
                    />

                    <mat-datepicker-toggle
                      matIconPrefix
                      [for]="deliveryPicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker
                      #deliveryPicker
                      startView="year"
                      [startAt]="startDate"
                    ></mat-datepicker>
                    <mat-error
                    *ngIf="signUpForm.get('birthdate')?.touched
                                        && signUpForm.get('birthdate')?.errors?.['required']
                                        "
                    class="text-xs"
                    >This field is required</mat-error
                  >
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="px-7 pb-3 col-span-1">
              <mat-form-field class="w-full" appearance="outline">
                <mat-label>Password</mat-label>
                <mat-icon matPrefix class="text-gray-700 text-md text-center"
                  >lock</mat-icon
                >
                <input
                  class="focus:ring-0"
                  matInput
                  [type]="tipo"
                  formControlName="password"
                />
                <mat-icon
                  matSuffix
                  class="cursor-pointer hover:text-primary-700"
                  (click)="mostrarContrasena()"
                  id="eye"
                  >visibility</mat-icon
                >
                <mat-icon
                  matSuffix
                  class="d-h cursor-pointer hover:text-primary-700"
                  (click)="mostrarContrasena()"
                  id="eyeSlash"
                  >visibility_off</mat-icon
                >
                <mat-error
                *ngIf="signUpForm.get('password')?.touched
                                    && signUpForm.get('password')?.errors?.['required']
                                    "
                class="text-xs"
                >This field is required</mat-error
              >
              <mat-error
              *ngIf="signUpForm.get('password')?.touched
              && signUpForm.get('password')?.errors?.['minlength']"
              class="text-xs"
              >min. 6 characters</mat-error
            >
            <mat-error
            *ngIf="signUpForm.get('password')?.touched
            && signUpForm.get('password')?.errors?.['maxlength']"
            class="text-xs"
            >max. 14 characters</mat-error
          >

          <mat-error
          *ngIf="signUpForm.get('password')?.touched
          && signUpForm.get('password')?.errors?.['pattern']"
          class="text-xs"
          > The password must contain at least one uppercase letter, one lowercase letter and one number.</mat-error
        >
              </mat-form-field>
            </div>

            <div class="px-7 pb-3 col-span-1">
              <mat-form-field class="w-full" appearance="outline">
                <mat-label>Confirm Password</mat-label>
                <mat-icon matPrefix class="text-gray-700 text-md text-center"
                  >lock</mat-icon
                >
                <input
                  class="focus:ring-0"
                  matInput
                  [type]="tipo2"
                  formControlName="confirmPassword"
                />
                <mat-icon
                  matSuffix
                  class="cursor-pointer hover:text-primary-700"
                  (click)="mostrarContrasena2()"
                  id="eye2"
                  >visibility</mat-icon
                >
                <mat-icon
                  matSuffix
                  class="d-h cursor-pointer hover:text-primary-700"
                  (click)="mostrarContrasena2()"
                  id="eyeSlash2"
                  >visibility_off</mat-icon
                >
                <mat-error
                *ngIf="signUpForm.get('confirmPassword')?.touched
                && signUpForm.get('confirmPassword')?.errors?.['required']
                "
                class="text-xs"
                >This field is required</mat-error
              >
              <mat-error
              *ngIf="signUpForm.get('confirmPassword')?.errors?.['confirmedValidator'] ||
              signUpForm.get('confirmPassword')?.errors?.['confirmedValidator']"
              class="text-xs"
              >Passwords do not match</mat-error
            >
            <mat-error
            *ngIf="signUpForm.get('confirmPassword')?.touched
            && signUpForm.get('confirmPassword')?.errors?.['minlength']"
            class="text-xs"
            >min. 6 characters</mat-error
          >
          <mat-error
          *ngIf="signUpForm.get('confirmPassword')?.touched
          && signUpForm.get('confirmPassword')?.errors?.['maxlength']"
          class="text-xs"
          >max. 14 characters</mat-error
        >
        <mat-error
        *ngIf="signUpForm.get('confirmPassword')?.touched
        && signUpForm.get('confirmPassword')?.errors?.['pattern']"
        class="text-xs"
        > The password must contain at least one uppercase letter, one lowercase letter and one number.</mat-error
      >
              </mat-form-field>
            </div>
            <div class="px-7 pb-3 col-span-1 md:col-span-2">
              <mat-checkbox
                class="example-margin"
                formControlName="checkConditions"
                >By registering you agree to the
                <b class="text-primary-700">terms and conditions</b> and our
                <b class="text-primary-700">privacy policy</b>.</mat-checkbox
              >
            </div>
          </div>
          <div class="flex justify-center">
            <button
              mat-flat-button
              color="primary"
              class="btn py-3 px-5"
              (click)="signUp()"
              [disabled]="signUpForm.invalid"
            >
              <mat-icon matSuffix>arrow_forward</mat-icon>
              Sign up
            </button>
          </div>
          <div class="flex justify-center pt-1 font-bold">
            <span
              >Do you have an account?
              <span
                class="text-primary-900 underline cursor-pointer"
                routerLink="/sign-in"
                >Sign in</span
              ></span
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
