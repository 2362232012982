import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { TokenStorageService } from '../../services/token-storage.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-recovery-password',
  templateUrl: './recovery-password.component.html',
  styleUrl: './recovery-password.component.css'
})
export class RecoveryPasswordComponent {
  recoveryForm: FormGroup<{
    email: FormControl<string | null>;
  }>;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.recoveryForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }


  sendOtp(){

     const email = this.recoveryForm.get('email')?.value || ''
      this.auth.sendOtp(email).subscribe({
        next: (sendResponse: any) => {
          if (sendResponse.status ==1) {
            this.auth.tokenRecovery = sendResponse.response.tokenRecovery;
            this.auth.emailRecovery=email;
            this.router.navigate(['/confirm-otp']);
            this.toastr.success('Send Success!');
          } else {
            this.toastr.error(sendResponse.response);
          }
        },
        error: (err) => {
          this.toastr.error('Error trying to recovery Password!');
        },
      });
    }
  




  
}
