<div class="w-full min-h-screen bgSignIn">
    <div class="relative">
      <back class="self-start absolute pt-6 pl-6"></back>
    </div>
    <div class="flex items-center justify-center flex-col min-h-screen sm:px-5">
      <div
        class="w-full dark:border md:mt-0 sm:max-w-lg xl:p-0 dark:bg-gray-800 dark:border-gray-700"
      >
        <div class="p-7 space-y-4 md:space-y-6">
            <form [formGroup]="valOtpForm" class="space-y-4 md:space-y-6 bgRecovery bg-white shadow">
              <div class="pt-1">
                <a>
                  <img
                    class="w-24 m-auto"
                    src="../../../../../assets/img/logo.png"
                    alt=""
                  />
                </a>
              </div>
                <div class="text-center flex flex-col mb-2 text-black">
                    <b class="bMg font-black h1Mg">Recovery Password</b
                    >
                    <p class="text-gray-500">Enter the validation code received by email</p>
                  </div>
            <div class="px-7 flex justify-center py-7">
              <p-inputOtp
                [(ngModel)]="value"
                formControlName="otp"
                [length]="6"

                >
                  <ng-template pTemplate="input" let-token let-events="events">
                      <input
                          pInputText
                          class="custom-otp-input"
                          (input)="events.input($event)"
                          (keydown)="events.keydown($event)"
                          type="text"
                          [attr.value]="token"
                          [maxLength]="1" />
                  </ng-template>
              </p-inputOtp>
            </div>
            <div class="flex justify-center ">
              <button mat-flat-button color="primary" class="btn py-3 px-5 text-white" (click)="validOtp()" [disabled]="valOtpForm.invalid">
                <mat-icon matSuffix>arrow_forward</mat-icon>
                Continue
              </button>
            </div>
            <div class="flex justify-center mt-5">
              <span>Did you not receive the mail? <b class="text-primary-700 cursor-pointer" (click)="sendOtp()">Click here</b></span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
