import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-valid-otp',
  templateUrl: './valid-otp.component.html',
  styleUrl: './valid-otp.component.css',
})
export class ValidOtpComponent {
  value: any;

  valOtpForm: FormGroup<{
    otp: FormControl<string | null>;
  }>;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.valOtpForm = this.fb.group({
      otp: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6)
        ],
      ],
    });
  }
  flag:boolean = false;
  validOtp() {
    this.flag = false;
    const otp = this.valOtpForm.get('otp')?.value || '';
    this.auth.confirmOtp(otp, this.auth.tokenRecovery).subscribe({
      next: (sendResponse: any) => {
        if (sendResponse.status == 1) {
          this.router.navigate(['/recovery-password']);
          this.toastr.success('OTP Confirm Success!');
        } else {
          this.toastr.error(sendResponse.response);
          this.flag = true;
        }
      },
      error: (err) => {
        this.toastr.error('Error trying to recovery Password!');
      },
    });
  }

  sendOtp() {
    const email = this.auth.emailRecovery;
    this.auth.sendOtp(email).subscribe({
      next: (sendResponse: any) => {
        if (sendResponse.status == 1) {
          this.auth.tokenRecovery = sendResponse.response.tokenRecovery;
          //  this.router.navigate(['/confirm-otp']);
          this.toastr.success('Send Success!');
        } else {
          this.toastr.error(sendResponse.response);
        }
      },
      error: (err) => {
        this.toastr.error('Error trying to recovery Password!');
      },
    });
  }
}
