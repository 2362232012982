import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-password-recovery',
  templateUrl: './change-password-recovery.component.html',
  styleUrl: './change-password-recovery.component.css'
})
export class ChangePasswordRecoveryComponent {


  changePaswordForm: FormGroup<{
    password: FormControl<string | null>
    confirmPassword: FormControl<string | null>
  }>
 
  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.changePaswordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(14)]],
      confirmPassword: ['',[Validators.required, Validators.minLength(6), Validators.maxLength(14)]],
    },
    {
      validator: this.ConfirmedValidator('password', 'confirmPassword')
    })
  }


  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors['confirmedValidator']
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  changePassword(){

    const password = this.changePaswordForm.get('password')?.value || ''
     this.auth.changePasswordOtp(password, this.auth.tokenRecovery).subscribe({
       next: (sendResponse: any) => {
         if (sendResponse.status ==1) {
           this.router.navigate(['/sign-in']);
           this.toastr.success('Change Password Success!');
         } else {
           this.toastr.error(sendResponse.response);
         }
       },
       error: (err) => {
         this.toastr.error('Error trying to recovery Password!');
       },
     });
   }




  tipo: any = 'password';
  mostrarContrasena() {
    const eye: any = document.getElementById('eye');
    const eyeSlash: any = document.getElementById('eyeSlash');

    if (this.tipo == 'password') {
      this.tipo = 'text';
      eye.classList.add('d-h');
      eyeSlash.classList.remove('d-h');
    } else {
      this.tipo = 'password';
      eye.classList.remove('d-h');
      eyeSlash.classList.add('d-h');
    }
  }

  tipo2: any = 'password';
  mostrarContrasena2() {
    const eye: any = document.getElementById('eye2');
    const eyeSlash: any = document.getElementById('eyeSlash2');

    if (this.tipo2 == 'password') {
      this.tipo2 = 'text';
      eye.classList.add('d-h');
      eyeSlash.classList.remove('d-h');
    } else {
      this.tipo2 = 'password';
      eye.classList.remove('d-h');
      eyeSlash.classList.add('d-h');
    }
  }
}
