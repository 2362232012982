<div class="w-full min-h-screen bgSignIn">
  <div class="relative">
    <back class="self-start absolute pt-6 pl-6"></back>
  </div>
  <div class="flex items-center justify-center min-h-screen flex-col sm:px-5">
    <div
      class="w-full dark:border md:mt-0 sm:max-w-lg xl:p-0 dark:bg-gray-800 dark:border-gray-700"
    >
      <div class="p-7 space-y-4 md:space-y-6">
        <form
          [formGroup]="changePaswordForm"
          class="space-y-4 md:space-y-6 bgRecovery bg-white shadow"
        >
        <div class="pt-1">
          <a>
            <img
              class="w-24 m-auto"
              src="../../../../../assets/img/logo.png"
              alt=""
            />
          </a>
        </div>
          <div class="text-center flex flex-col  mb-2 text-black">
            <b class="bMg font-black h1Mg">Recovery Password</b>
            <p class="text-gray-500">Enter your new password</p>
          </div>
          <div class="px-7">
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>New Pasword</mat-label>
              <mat-icon matPrefix class="text-gray-700 text-md text-center"
                >lock</mat-icon
              >
              <input
                class="focus:ring-0"
                matInput
                [type]="tipo"
                required=""
                formControlName="password"
              />
              <mat-icon
                matSuffix
                class="cursor-pointer hover:text-primary-700"
                (click)="mostrarContrasena()"
                id="eye"
                >visibility</mat-icon
              >
              <mat-icon
                matSuffix
                class="d-h cursor-pointer hover:text-primary-700"
                id="eyeSlash"
                (click)="mostrarContrasena()"
                >visibility_off</mat-icon
              >

              <mat-error
                *ngIf="changePaswordForm.get('password')?.touched
                                        && changePaswordForm.get('password')?.errors?.['required']
                                      "
                class="text-xs"
                >This field is required</mat-error
              >

              <mat-error
                *ngIf="changePaswordForm.get('password')?.touched
                  && changePaswordForm.get('password')?.errors?.['minlength']"
                class="text-xs"
                >min. 6 characters</mat-error
              >

              <mat-error
                *ngIf="changePaswordForm.get('password')?.touched
                && changePaswordForm.get('password')?.errors?.['maxlength']"
                class="text-xs"
                >max. 14 characters</mat-error
              >
            </mat-form-field>
          </div>
          <div class="px-7">
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Confirm Pasword</mat-label>
              <mat-icon matPrefix class="text-gray-700 text-md text-center"
                >lock</mat-icon
              >
              <input
                class="focus:ring-0"
                matInput
                [type]="tipo2"
                required=""
                formControlName="confirmPassword"
              />
              <mat-icon
                matSuffix
                class="cursor-pointer hover:text-primary-700"
                (click)="mostrarContrasena2()"
                id="eye2"
                >visibility</mat-icon
              >
              <mat-icon
                matSuffix
                class="d-h cursor-pointer hover:text-primary-700"
                id="eyeSlash2"
                (click)="mostrarContrasena2()"
                >visibility_off</mat-icon
              >
              <mat-error
                *ngIf="changePaswordForm.get('confirmPassword')?.touched
                                        && changePaswordForm.get('confirmPassword')?.errors?.['required']
                                      "
                class="text-xs"
                >This field is required</mat-error
              >

              <mat-error
                *ngIf="changePaswordForm.get('confirmPassword')?.touched
                  && changePaswordForm.get('confirmPassword')?.errors?.['minlength']"
                class="text-xs"
                >min. 6 characters</mat-error
              >

              <mat-error
                *ngIf="changePaswordForm.get('confirmPassword')?.touched
                && changePaswordForm.get('confirmPassword')?.errors?.['maxlength']"
                class="text-xs"
                >max. 14 characters</mat-error
              >

              <mat-error
                *ngIf="changePaswordForm.get('confirmPassword')?.errors?.['confirmedValidator'] ||
              changePaswordForm.get('confirmPassword')?.errors?.['confirmedValidator']"
                class="text-xs"
                >Passwords do not match</mat-error
              >
            </mat-form-field>
          </div>
          <div class="flex justify-center">
            <button
              mat-flat-button
              color="primary"
              class="btn py-3 px-5 text-white"
              (click)="changePassword()"
              [disabled]="changePaswordForm.invalid"
            >
              <mat-icon matSuffix>arrow_forward</mat-icon>
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
