import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { TokenStorageService } from '../../services/token-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../interface/interfaces';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MealsCartService } from 'src/app/modules/fit-meals-v2/services/meals-cart.service';
import { Cart } from 'src/app/modules/fit-meals-v2/interfaces/cart.interface';
import { Message } from 'primeng/api';

@Component({
  templateUrl: './sign-in-page.component.html',
  styleUrls: ['./sign-in-page.component.css'],
})
export class SignInPageComponent {
  loginForm: FormGroup<{
    email: FormControl<string | null>;
    password: FormControl<string | null>;
  }>;

  user: User = {
    email: '',
    password: '',
  };

  @ViewChild('userInput') userInput!: ElementRef<HTMLInputElement>;
  @ViewChild('checkedInput') checkedInput!: MatSlideToggle;

  rememberMe: any = false;
  username: string = '';
  messages: Message[] = [];
  isError = {
    error: false,
    message: '',
  }

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private token: TokenStorageService,
    private cartService: MealsCartService,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(14), 
        Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]+$/)
      ]],
    });
  }
  ngAfterViewInit(): void {
    this.rememberMe = localStorage.getItem('rememberMe');

    if (this.rememberMe == 'Yes') {
      this.loginForm.controls['email'].setValue(
        localStorage.getItem('username')
      );
      this.checkedInput.checked = true;
    }
  }

  getUser() {
    this.rememberMe = false;
    this.user.email = this.loginForm.get('email')?.value || '';
    this.user.password = this.loginForm.get('password')?.value || '';

    this.auth.loginCLient(this.user).subscribe({
      next: (loginResponse: any) => {
        if (loginResponse.hasOwnProperty('access_token')) {
          let login_ = loginResponse;
          this.auth.isLoged = of(true);
          this.token.SaveTokenClient(login_);
          this.auth.menu = login_.menu;

          // Primero, toma el valor del mat-slide-toggle
          this.rememberMe = this.checkedInput.checked;

          // Luego, puedes validar el valor y realizar acciones basadas en él
          if (this.rememberMe === true) {
            localStorage.setItem('rememberMe', 'Yes');
            localStorage.setItem('username', this.user.email);
          } else {
            localStorage.setItem('rememberMe', 'No');
            localStorage.setItem('username', '');
          }

          const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
          this.router.navigateByUrl(returnUrl);
          // this.router.navigate(['/profile']);
          this.cartService.cart = {
            products: [],
            priceTotal: 0,
            quantityTotal: 0,
          };
          localStorage.removeItem('goal');
          this.toastr.success('Welcome to MG Meals!');
        } else {
          this.toastr.error(loginResponse.response);
          this.isError = {
            error: true,
            message: loginResponse.response
          }
          // this.messages = [
          //   { severity: 'error', detail: loginResponse.response },
          // ];
        }
      },
      error: (err) => {
        this.toastr.error('Error trying to log in!');
        // this.messages = [
        //   { severity: 'error', detail: 'Error trying to log in!' },
        // ];
        this.isError = {
          error: true,
          message: 'Error trying to log in!',
        };
      },
    });
  }

  tipo: any = 'password';
  mostrarContrasena() {
    const eye: any = document.getElementById('eye');
    const eyeSlash: any = document.getElementById('eyeSlash');

    if (this.tipo == 'password') {
      this.tipo = 'text';
      eye.classList.add('d-h');
      eyeSlash.classList.remove('d-h');
    } else {
      this.tipo = 'password';
      eye.classList.remove('d-h');
      eyeSlash.classList.add('d-h');
    }
  }
}
