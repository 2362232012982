<div class="w-full min-h-screen bgSignIn">
    <div class="relative">
      <back class="self-start absolute pt-6 pl-6"></back>
    </div>
    <div class="flex items-center justify-center flex-col min-h-screen sm:px-5">
      <div
        class="w-full dark:border md:mt-0 sm:max-w-lg xl:p-0 dark:bg-gray-800 dark:border-gray-700"
      >
        <div class="p-7 space-y-4 md:space-y-6">
            <form [formGroup]="recoveryForm" class="space-y-4 md:space-y-6 bgRecovery bg-white shadow">
              <div class="py-1">
                <a>
                  <img
                    class="w-24 m-auto mb-2"
                    src="../../../../../assets/img/logo.png"
                    alt=""
                  />
                </a>
              </div>
                <div class="text-center flex flex-col mb-2 text-black">
                    <b class="bMg h1Mg font-black mb-2">Recovery Password</b
                    >
                    <p class="text-gray-500">Enter your email to send you a validation code</p>
                  </div>
            <div class="px-7">
              <mat-form-field class="w-full" appearance="outline">
                <mat-icon matPrefix class="text-gray-700 text-md text-center"
                  >mail</mat-icon
                >
                <mat-label>Email</mat-label>
                <input class="focus:ring-0" matInput type="email" required="" formControlName="email" />
                <mat-error
                *ngIf="recoveryForm.get('email')?.touched
                && recoveryForm.get('email')?.errors?.['required']
                "
                class="text-xs"
                >This field is required</mat-error
              >
              <mat-error
                *ngIf="recoveryForm.get('email')?.touched
                                && recoveryForm.get('email')?.errors?.['email']
                                "
                class="text-xs"
                >Invalid Email</mat-error
              >
              </mat-form-field>
            </div>
            <div class="flex justify-center ">
              <button mat-flat-button color="primary" class="btn py-3 px-5 text-white" (click)="sendOtp()" [disabled]="recoveryForm.invalid">
                <mat-icon matSuffix>arrow_forward</mat-icon>
                Continue
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  