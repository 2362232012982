import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ResponseSignUp, UserSignUp } from '../../interface/interfaces';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  templateUrl: './sign-up-page.component.html',
  styleUrls: ['./sign-up-page.component.css']
})
export class SignUpPageComponent {
  signUpForm: FormGroup<{
    firtName: FormControl<string | null>
    lastName: FormControl<string | null>
    email: FormControl<string | null>
    phone: FormControl<string | null>
    birthdate: FormControl<string | null>
    password: FormControl<string | null>
    confirmPassword: FormControl<string | null>
    checkConditions: FormControl<boolean | null>
  }>

  user: UserSignUp = {
   name:'',
   lastName:'',
   email: '',
   phone: '',
   password: '',
   birthdate: ''
  }
  startDate = new Date(1999, 0, 1);
  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.signUpForm = this.fb.group({
      firtName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10),Validators.pattern('^[0-9]*$')]],
      birthdate: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(14), 
        Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]+$/)
      ]],
      confirmPassword: ['',[Validators.required, Validators.minLength(6), Validators.maxLength(14), 
        Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]+$/)
      ]],
      checkConditions: [false, Validators.requiredTrue]
    },
    {
      validator: this.ConfirmedValidator('password', 'confirmPassword')
    })
  }

  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors['confirmedValidator']
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }


  tipo: any = 'password';
  mostrarContrasena() {
    const eye: any = document.getElementById('eye');
    const eyeSlash: any = document.getElementById('eyeSlash');

    if (this.tipo == 'password') {
      this.tipo = 'text';
      eye.classList.add('d-h');
      eyeSlash.classList.remove('d-h');
    } else {
      this.tipo = 'password';
      eye.classList.remove('d-h');
      eyeSlash.classList.add('d-h');
    }
  }

  tipo2: any = 'password';
  mostrarContrasena2() {
    const eye: any = document.getElementById('eye2');
    const eyeSlash: any = document.getElementById('eyeSlash2');

    if (this.tipo2 == 'password') {
      this.tipo2 = 'text';
      eye.classList.add('d-h');
      eyeSlash.classList.remove('d-h');
    } else {
      this.tipo2 = 'password';
      eye.classList.remove('d-h');
      eyeSlash.classList.add('d-h');
    }
  }

  signUp(){

    var fecha = this.signUpForm.get('birthdate')?.value || '';
    var fecha2 = new Date(fecha).toISOString().slice(8, 10) + '-' + new Date(fecha).toISOString().slice(5, 7) + '-' + new Date(fecha).toISOString().slice(0, 4);
    this.user = {
      name: this.signUpForm.get('firtName')?.value || '',
      lastName: this.signUpForm.get('lastName')?.value || '',
      email: this.signUpForm.get('email')?.value || '',
      phone: (this.signUpForm.get('phone')?.value || 0).toString(),
      birthdate: fecha2,
      password: this.signUpForm.get('password')?.value || ''
    }
 
    this.auth.signUp(this.user).subscribe({
      next: (SignUpResponse: ResponseSignUp) => {
        if (SignUpResponse.status == 1) {
          this.toastr.success(SignUpResponse.response);
          this.router.navigate(['/sign-in']);
         } else {
          this.toastr.error(SignUpResponse.response);
        }
      },
      error: (err) => {
        this.toastr.error('Error trying to Sign Up!');
      }
    });
  }
}
