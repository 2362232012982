<div class="w-full min-h-screen bgSignIn">
  <div class="relative">
    <back class="self-start absolute pt-6 pl-6"></back>
  </div>
  <div class="flex items-center justify-center flex-col min-h-screen sm:px-5">
    <div></div>
    <div
      class="w-full dark:border md:mt-0 sm:max-w-lg xl:p-0 dark:bg-gray-800 dark:border-gray-700"
    >
      <div class="p-6 space-y-4 md:space-y-6">
        <form
          [formGroup]="loginForm"
          class="space-y-4 md:space-y-6 bgEsp shadow"
        >
          <div class="pt-1">
            <a>
              <img
                class="w-24 m-auto mb-2"
                src="../../../../../assets/img/logo.png"
                alt=""
              />
            </a>
          </div>
          <div class="text-center flex flex-col h1Mg text-black">
            <b class="bMg font-black">Welcome Back</b>
          </div>
          <div class="px-7">
            <mat-form-field class="w-full" appearance="outline">
              <mat-icon matPrefix class="text-gray-700 text-md text-center"
                >mail</mat-icon
              >
              <mat-label>Email</mat-label>
              <input
                class="focus:ring-0"
                matInput
                type="email"
                required=""
                formControlName="email"
              />
              <mat-error
                *ngIf="loginForm.get('email')?.touched
              && loginForm.get('email')?.errors?.['required']
              "
                class="text-xs"
                >This field is required</mat-error
              >
              <mat-error
                *ngIf="loginForm.get('email')?.touched
              && loginForm.get('email')?.errors?.['email']
              "
                class="text-xs"
              >
                Invalid Email</mat-error
              >
            </mat-form-field>
          </div>
          <div class="px-7">
            <mat-form-field class="w-full" appearance="outline">
              <mat-label>Password</mat-label>
              <mat-icon matPrefix class="text-gray-700 text-md text-center"
                >lock</mat-icon
              >
              <input
                class="focus:ring-0"
                matInput
                [type]="tipo"
                required=""
                formControlName="password"
              />
              <mat-icon
                matSuffix
                class="cursor-pointer hover:text-primary-700"
                (click)="mostrarContrasena()"
                id="eye"
                >visibility</mat-icon
              >
              <mat-icon
                matSuffix
                class="d-h cursor-pointer hover:text-primary-700"
                (click)="mostrarContrasena()"
                id="eyeSlash"
                >visibility_off</mat-icon
              >
              <!-- <mat-icon matSuffix class="cursor-pointer" (click)="mostrarContrasena()">visibility</mat-icon> -->
              <mat-error
                *ngIf="loginForm.get('password')?.touched
              && loginForm.get('password')?.errors?.['required']
              "
                class="text-xs"
              >
                This field is required</mat-error
              >
              <mat-error
              *ngIf="loginForm.get('password')?.touched
              && loginForm.get('password')?.errors?.['minlength']"
              class="text-xs"
              >min. 6 characters</mat-error
            >
            <mat-error
            *ngIf="loginForm.get('password')?.touched
            && loginForm.get('password')?.errors?.['maxlength']"
            class="text-xs"
            >max. 14 characters</mat-error
          >
              <mat-error
                *ngIf="loginForm.get('password')?.touched
                      && loginForm.get('password')?.errors?.['pattern']"
                class="text-xs"
              >
              Password does not meet the criteria</mat-error
              >
            </mat-form-field>
            <div class="flex justify-end mt-3">
              <p
                class="text-sm cursor-pointer text-primary-900 underline"
                routerLink="/recovery"
              >
                Forgot password
              </p>
            </div>
            <div>
              <p>
                <mat-slide-toggle color="primary" #checkedInput
                  ><span class="ps-1">Remember me!</span></mat-slide-toggle
                >
              </p>
            </div>
          </div>
          <div class="flex justify-center">
            <button
              mat-flat-button
              color="primary"
              class="btn btnContinue py-3 px-5"
              (click)="getUser()"
              [disabled]="loginForm.invalid"
            >
              <mat-icon matSuffix>arrow_forward</mat-icon>
              Sign in
            </button>
          </div>
          <div class="flex justify-center" *ngIf="isError.error">
            <div
              class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative min-w-60"
              role="alert"
            >
              <span class="block sm:inline">{{ isError.message }}</span>
              <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
                <svg
                  class="fill-current h-6 w-6 text-red-500"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <title>Close</title>
                  <path
                    d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
                  />
                </svg>
              </span>
            </div>
            <!-- <p-messages [(value)]="messages" [enableService]="false"/> -->
          </div>
          <div class="flex justify-center pt-1 font-bold">
            <span
              >Dont have account?
              <span
                class="text-primary-900 underline cursor-pointer"
                routerLink="/sign-up"
                >Sign up</span
              ></span
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
